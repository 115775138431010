import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material';

@Component({
  selector: 'app-conversion-stepper-control',
  templateUrl: './conversion-stepper-control.component.html',
  styleUrls: ['./conversion-stepper-control.component.css']
})
export class ConversionStepperControlComponent implements OnInit {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  constructor() { }

  ngOnInit(): void { }

  goToNextForm(): void {
    this.stepper.next();
  }

  goToPreviousForm(): void {
    this.stepper.previous();
  }

  startOver(): void {
    this.stepper.reset();
  }
}
