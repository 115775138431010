import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of, BehaviorSubject, Observable, throwError } from 'rxjs';
import providers from '../../../assets/json/providers.json';

import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { TraceIdService } from 'src/app/enrollment-forms/services/trace-id.service';
import { PlatformLocation } from '@angular/common';
import { PlatformSubscriberValidateRequest } from '../models/platform-subscriber-validate-request';
import { PlatformSubscriberValidateResponse } from '../models/platform-subscriber-validate-response';
import { ProductInformation } from '../models/product-information';
import { PlatformSubscriberPaymentRequest } from '../models/platform-subscriber-payment-request';
import { PaymentInformation } from '../models/payment-information';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PaymentLineItem } from 'src/app/shared/models/payment-line-item.model';

const sampleResponse: PlatformSubscriberValidateResponse = {
  hasMultipleSubscribers: true,
  hasFallDetection: true,
  hasMultipleSites: false,
  hasMultipleMobileDevices: true,
  isPrivatePayer: false,
  siteNo: 0,
  systemFamily: 'CEL',
  systemType: '7200C'
};

@Injectable({
  providedIn: 'root'
})
export class ConversionFormService {
  formConversion: FormGroup;
  formPayment: FormGroup;
  productInformation$ = new BehaviorSubject<ProductInformation>(new ProductInformation());
  providerDetails$: BehaviorSubject<Provider> = new BehaviorSubject<Provider>(null);
  isLoggedIn = false;
  siteNumber = null;

  lastSubmittedConversion = {
    firstName: '',
    lastName: '',
    price: 0
  };

  constructor(
    private httpClient: HttpClient,
    private appRoute: PlatformLocation,
    private appConfig: AppConfig,
    private traceIdService: TraceIdService,
    private authService: AuthService
  ) {
    const affiliateName = this.appRoute.pathname.split('/')[2];

    const currentProvider = providers.providers.find(
      element => element.name && element.name.toUpperCase() === affiliateName.toUpperCase()
    );

    this.providerDetails$.next(currentProvider);

    this.authService.isLoggedIn$().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

  }

  verifyMember$(
    affiliationGroup: string,
    memberNo: string,
    siteNo: number,
    serialNumber: string,
    lastName: string
  ): Observable<PlatformSubscriberValidateResponse> {
    const platformSubscriberValidateRequest: PlatformSubscriberValidateRequest = {
      affiliationGroup,
      memberNo: memberNo ? memberNo : null,
      siteNo: siteNo ? Number(siteNo) : null,
      serialNumber: serialNumber ? serialNumber : null,
      lastName
    };

    const externalLookupOptions = this.enrollmentApiOptions();

    if (this.appConfig.config.fakePlatformCalls) {
      return this.simulateVerificationHttpCall(lastName, memberNo);
    }

    return this.httpClient.post<PlatformSubscriberValidateResponse>(
      this.appConfig.config.enrollmentApiValidate,
      platformSubscriberValidateRequest,
      externalLookupOptions);
  }

  submitPayment$(paymentInformation: PaymentInformation): Observable<any> {
    const repUserJson = localStorage.getItem(this.appConfig.config.localStorageRepUser);
    let repUserFormatted = 'Subscriber (self)';

    if (this.isLoggedIn && repUserJson) {
      const repUser = JSON.parse(repUserJson);
      repUserFormatted = `${repUser.userName} (${repUser.firstName} ${repUser.lastName})`;
    }

    const platformSubscriberPaymentRequest: PlatformSubscriberPaymentRequest = {
      affiliationGroup: 'UHC_FFS',
      lineItems: paymentInformation.paymentLineItems.map((paymentLineItems: PaymentLineItem) => ({
        billCode: paymentLineItems.billCode,
        description: paymentLineItems.description,
        amount: paymentLineItems.amount
      })),
      convertedBy: repUserFormatted,
      siteNumber: this.siteNumber,
      firstName: paymentInformation.firstName,
      lastName: paymentInformation.lastName,
      address1: paymentInformation.streetAddress,
      city: paymentInformation.city,
      state: paymentInformation.state,
      zipcode: paymentInformation.zipcode,
      creditCard: {
        accountNumber: paymentInformation.accountNumber,
        routingNumber: paymentInformation.routingNumber,
        expirationDate: paymentInformation.endYear ?
          // Intentionally "adds" a month (by not accounting for the month being zero-based in Date.UTC)
          // so expirationDate is midnight the first day of the following month
          new Date(Date.UTC(Number(paymentInformation.endYear), Number(paymentInformation.endMonth))) : new Date(),
        endDate: paymentInformation.endYear ? paymentInformation.endYear + '-' + paymentInformation.endMonth : null,
        holderName: paymentInformation.holderName ? paymentInformation.holderName: paymentInformation.firstName +" "+ paymentInformation.lastName,
        cardType: paymentInformation.cardType
      },
      phoneNumber: paymentInformation.phoneNumber
    };

    //Simulate 500 and test with real Platform API call in QA
    /*const platformSubscriberPaymentRequest: PlatformSubscriberPaymentRequest = {
      affiliationGroup: 'UHC_FFS',
      lineItems: paymentInformation.paymentLineItems.map((paymentLineItems: PaymentLineItem) => ({
        billCode: 'MAWACT',
        description: 'On the Go+',
        amount: 39.95
      })),
      convertedBy: repUserFormatted,
      siteNumber: 30369494,
      firstName: 'RACHIT',
      lastName: 'WILSON',
      address1: paymentInformation.streetAddress,
      city: paymentInformation.city,
      state: paymentInformation.state,
      zipcode: paymentInformation.zipcode,
      creditCard: {
        accountNumber: paymentInformation.accountNumber,
        routingNumber: paymentInformation.routingNumber,
        expirationDate: paymentInformation.endYear ?
          // Intentionally "adds" a month (by not accounting for the month being zero-based in Date.UTC)
          // so expirationDate is midnight the first day of the following month
          new Date(Date.UTC(Number(paymentInformation.endYear), Number(paymentInformation.endMonth))) : new Date(),
        endDate: paymentInformation.endYear ? paymentInformation.endYear + '-' + paymentInformation.endMonth : null,
        holderName: 'RACHIT WILSON',
        cardType: paymentInformation.cardType
      },
      phoneNumber: "5089881117"
    };*/

    this.lastSubmittedConversion.firstName = paymentInformation.firstName;
    this.lastSubmittedConversion.lastName = paymentInformation.lastName;
    this.lastSubmittedConversion.price = paymentInformation.price;

    const externalLookupOptions = this.enrollmentApiOptions();

    if (this.appConfig.config.fakePlatformCalls) {
      console.log(platformSubscriberPaymentRequest);
      console.log(JSON.stringify(platformSubscriberPaymentRequest));
      return this.simulatePaymentHttpCall(paymentInformation.firstName);
    }

    return this.httpClient.post(
      this.appConfig.config.enrollmentApiPayment,
      platformSubscriberPaymentRequest,
      externalLookupOptions);
  }

  clearForms() {
    this.traceIdService.generateNewId();
    this.formConversion = new FormGroup({});
    this.formPayment = new FormGroup({});
    this.productInformation$.next(new ProductInformation());
  }

  private enrollmentApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('AppId', this.appConfig.config.enrollmentAppId)
        .set('AppSecret', this.appConfig.config.enrollmentAppSecret)
        .set('Content-Type', 'application/json')
    };
  }

  private simulateVerificationHttpCall(lastName, memberNo) {
    if (lastName === 'FAIL') {
      if (memberNo === '404') {
        return throwError(new HttpErrorResponse({ status: 404 }));
      } else if (memberNo === '409') {
        return throwError(new HttpErrorResponse({ status: 409 }));
      } else if (memberNo === '422') {
        return throwError(new HttpErrorResponse({ status: 422 }));
      } else {
        return throwError(new HttpErrorResponse({ status: 500 }));
      }
    }

    return of(sampleResponse);
  }

  private simulatePaymentHttpCall(firstName) {
    if (firstName === 'FAIL') {
      return throwError(new HttpErrorResponse({ status: 500 }));
    }

    return of(null);
  }
}
