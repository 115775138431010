import { PlatformLocation } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import providers from '../../../assets/json/providers.json';
import { Bundles } from '../models/bundles.model';
import { EligibilityCheckRequest } from '../models/eligibility-check-request.model';
import { Provider } from '../models/provider.model';
import { Verify } from '../models/user.model';
import { PlatformApiService } from './platform-api.service';
import { Product } from 'src/app/shared/models/product.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { ProductCatalog } from 'src/app/shared/models/product-catalog.model';
import { TraceIdService } from './trace-id.service';
import { TokenInformation } from '../models/token-information';
import { Account } from '../models/account.model';
import { Bundle } from 'src/app/shared/models/bundle.model';
import { switchMap } from 'rxjs/operators';

const careRecipientEmptyFormValues = {
  firstName: '',
  lastName: '',
  addressOne: '',
  addressTwo: '',
  aptNo: '',
  city: '',
  email: '',
  emailMatch: '',
  addressOverride: false,
  gender: '',
  phone: [],
  dob: '',
  houseKeyExists: false,
  language: '',
  locationOfHouseKey: '',
  middleName: '',
  shippingInfo: false,
  state: '',
  zipCode: ''
};

const caregiverEmptyFormValues = {
  details: [
    {
      firstName: '',
      middleName: '',
      lastName: '',
      phoneDetails: [
        {
          carePhone: '',
          carePhoneType: ''
        }
      ],
      email: '',
      emailMatch: '',
      relationship: '',
      role: [],
      language: ''
    }
  ]
};

const productDetailsEmptyFormValues = {
  lineNo: '',
  lineNoMatch: '',
  cellNo: '',
  cellNoMatch: '',
  lineChoice: '',
  cellChoice: '',
  serviceLineChoice: '',
  serviceCellChoice: '',
  otgPlusSelected: false,
  otgAwayServiceSelected: false,
  otgConsent: {
    mobSelected: false,
    awayServiceSelected: false,
  }
};

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  showSaveButton$ = new BehaviorSubject<boolean>(false);

  formMemberVerification$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formVerifyServices$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formMemberInfo$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formShippingInfo$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formProductInfo$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  formCaregiverInfo$ = new BehaviorSubject<FormGroup>(new FormGroup({}));

  productInfo$ = new BehaviorSubject<Product>(null);
  tokenInformation$ = new BehaviorSubject<TokenInformation>(new TokenInformation());
  careDetails$: BehaviorSubject<Provider> = new BehaviorSubject<Provider>(null);
  useDefaultLogo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  validateCaregiverForm$$: Observable<boolean>;
  isCaregiverFormValid$: Observable<boolean>;

  membershipDetails: FormGroup;
  memberInfoDetails: FormGroup;
  memberShippingDetails: FormGroup;
  productDetails: FormGroup;
  caregiverDetails: FormGroup;

  shippingDetailsEmptyFormValues: any = {
    shipFirstName: '',
    shipMiddleName: '',
    shipLastName: '',
    shipAddressOne: '',
    shipAddressTwo: '',
    shipAptNo: '',
    shipCity: '',
    shipState: '',
    shipZipCode: '',
    shipEmail: '',
    shipEmailMatch: '',
    shipPhone: '',
    shipPhoneMatch: '',
  };

  private affiliateName: string;

  private validateCaregiverFormSource$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isCaregiverFormValidSource$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private httpClient: HttpClient,
    private appRoute: PlatformLocation,
    private platformApiService: PlatformApiService,
    private appConfig: AppConfig,
    private traceIdService: TraceIdService
  ) {
    this.validateCaregiverForm$$ = this.validateCaregiverFormSource$.asObservable();
    this.isCaregiverFormValid$ = this.isCaregiverFormValidSource$.asObservable();

    this.setDefaultProvider();
  }

  setDefaultProvider(affiliateName?: string) {
    if (affiliateName) {
      this.setAffiliateName(affiliateName);
    } else {
      this.setAffiliateNameFromPath();
    }

    const currentProviders = this.getCurrentProviders();

    if (currentProviders.length > 0) {
      this.careDetails$.next(currentProviders[0]);
    }
  }

  getFullAddressInfo(street: string, city: string, state: string, zip: string) {
    const fullAdressParams = {
      candidates: '1',
      street,
      city,
      state,
      zip
    };

    const httpOptions = { params: fullAdressParams };
    return this.httpClient.get(this.appConfig.config.addressApiStreetAddress, httpOptions);
  }

  verifyMemberInformation(
    affiliate: string,
    externalid: string,
    memberid: string,
    dob: string,
    lastName: string,
    firstName: string,
    zip: string
  ) {
    const externalLookupParams: EligibilityCheckRequest = {
      externalIdType1: externalid,
      externalIdValue1: memberid,
      affiliate,
      dateOfBirth: dob,
      lastName,
      firstName,
      zip
    };

    const externalLookupOptions = this.enrollmentApiOptions();
    return this.httpClient.post<Verify>(this.appConfig.config.enrollmentApiExternalLookup, externalLookupParams, externalLookupOptions);
  }

  listZipCodesWithCoverage() {
    return this.httpClient.get('/ssep/assets/json/coverage.json');
  }

  productSelection() {
    return this.httpClient.get<ProductCatalog>('/ssep/assets/json/products.json');
  }

  getCurrentProviders(): Provider[] {
    if (!this.affiliateName) {
      this.setAffiliateNameFromPath();
      if (!this.affiliateName) {
        return [];
      }
    }

    return providers.providers.filter(element => element.name && element.name.toUpperCase() === this.affiliateName.toUpperCase())
      .map((provider: Provider) => {
        provider.disableAwayService = provider.disableAwayService || this.appConfig.config.disableAwayServiceGlobally;
        return provider;
      })
      .sort((a, b) => {
        if (a.hasOwnProperty('default') && a.default) {
          return -1;
        }

        if (b.hasOwnProperty('default') && b.default) {
          return 1;
        }

        if (a.planname && b.planname) {
          const nameA = a.planname.toLowerCase();
          const nameB = b.planname.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        }
        return 0;
      });
  }

  setSelectedProvider(provider: Provider): void {
    if (provider) {
      this.affiliateName = provider.name;
      this.careDetails$.next(provider);
    }
  }

  createAccount$(accounts: Account[], bundleNumber: string): Observable<any> {
    return this.getBundleOffering().pipe(
      switchMap((productBundles) => {
        const bundleEnv = this.appConfig.config.environmentName;
        const offering: Bundle = productBundles[bundleEnv].find((bundle: Bundle) => bundle.bundleNo === bundleNumber);

        const jobNumbers: string[] = [];

        if (offering) {
          jobNumbers.push(offering.jobNo);
        }

        accounts.find(a => a.isCareRecipient).jobNumbers = jobNumbers;
        return this.platformApiService.createAccount$(accounts);
      })
    );
  }

  getBundleOffering() {
    return this.httpClient.get<Bundles>('/ssep/assets/json/productbundle.json');
  }

  clearForms() {
    this.traceIdService.generateNewId();

    this.showSaveButton$.next(false);

    this.formMemberVerification$.next(new FormGroup({}));
    this.formVerifyServices$.next(new FormGroup({}));
    this.formMemberInfo$.next(new FormGroup({}));
    this.formShippingInfo$.next(new FormGroup({}));
    this.formProductInfo$.next(new FormGroup({}));
    this.formCaregiverInfo$.next(new FormGroup({}));

    this.productInfo$.next(null);
    this.tokenInformation$.next(new TokenInformation());

    if (this.productDetails) {
      this.productDetails.reset(productDetailsEmptyFormValues);
    }

    if (this.memberInfoDetails) {
      this.memberInfoDetails.reset(careRecipientEmptyFormValues);
    }

    if (this.memberShippingDetails) {
      this.memberShippingDetails.reset(this.shippingDetailsEmptyFormValues);
    }

    if (this.caregiverDetails) {
      this.caregiverDetails.reset(caregiverEmptyFormValues);
    }

    this.useDefaultLogo$.next(true);
    this.setDefaultProvider();
  }

  verifyEligibility() {
    return this.httpClient.get('/ssep/assets/json/verify.json');
  }

  sendConfirmationEmail(recipientEmailAddress: string, emailText: string = 'congratulations') {
    return this.sendEmail(this.appConfig.config.enrollmentApiMessageEnroll, recipientEmailAddress, emailText,' ');
  }

  sendConversionEmail(recipientEmailAddress: string,emailText: string, ammount: string) {
    return this.sendEmail(this.appConfig.config.enrollmentApiMessageConvert, recipientEmailAddress, emailText,ammount);
  }


  validateCaregiverForm() {
    this.validateCaregiverFormSource$.next(true);
  }

  updateCaregiverFormValid(isCaregiverFormValid: boolean) {
    this.isCaregiverFormValidSource$.next(isCaregiverFormValid);
  }

  private sendEmail(endpoint: string, recipientEmailAddress: string, emailType: string, sender: string) {
    const sendEmailBody = {
      bodyType: emailType,
      sender: sender,  // As of this writing the API requires the field but ignores the value.
      receiver: recipientEmailAddress
    };
    return this.httpClient.post(endpoint, sendEmailBody);
  }

  private enrollmentApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('AppId', this.appConfig.config.enrollmentAppId)
        .set('AppSecret', this.appConfig.config.enrollmentAppSecret)
        .set('Content-Type', 'application/json')
    };
  }

  private setAffiliateName(affiliateName: string) {
    this.affiliateName = affiliateName;
  }

  private setAffiliateNameFromPath() {
    this.affiliateName = this.appRoute.pathname.split('/')[2];
  }
}
