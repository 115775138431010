import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export class AppConfigValues {
    production?: boolean;
    environmentName?: string;
    addressApi?: string;
    addressApiPayerconfig?: string;
    addressApiStreetAddress?: string;
    addressApiStreetAddressTimeout? = 30000;
    awsRumApplicationId?: string;
    awsRumGuestRoleArn?: string;
    awsRumIdentityPoolId?: string;
    captcha?: string;
    debug? = false;
    disableAwayServiceGlobally?: boolean;
    enrollmentApi?: string;
    enrollmentApiExternalLookup?: string;
    enrollmentApiValidate?: string;
    enrollmentApiPayment?: string;
    enrollmentApiMessageConvert?: string;
    enrollmentApiMessageEnroll?: string;
    enrollmentApiOktaAuth?: string;
    enrollmentApiOktaLogout?: string;
    enrollmentApiSearchBase?: string;
    enrollmentApiSearchMbi?: string;
    enrollmentApiSearchName?: string;
    enrollmentApiSearchPhone?: string;
    enrollmentApiSsepAuth?: string;
    enrollmentAppId?: string;
    enrollmentAppSecret?: string;
    humanapayerid?: string;
    isOverridePayer?: boolean;
    localStorageFirstClickAt?: string;
    localStoragePlatformToken?: string;
    localStorageRepUser?: string;
    localStorageVerifyCount?: string;
    logAllHttpEvents? = false;
    logAllHttpEventsExceedingMilliseconds? = 30000;
    logAddressApi? = true;
    logEnrollmentApi? = true;
    logEnrollmentApiSearch?;
    logPlatformApi? = true;
    platformApi?: string;
    platformApiAccounts?: string;
    platformApiEligibility?: string;
    timerDuration?: number;
    timerValue?: number;
    uhcpayerid?: string;
    fakePlatformCalls? = false;
}

@Injectable({
    providedIn: 'root'
})
export class AppConfig {
    config: AppConfigValues;

    constructor(private http: HttpClient) {
        this.config = {} as AppConfigValues;
    }

    async load() {
        this.config = await this.http.get<AppConfigValues>('/ssep/assets/config/config.json').toPromise();
        this.config.disableAwayServiceGlobally = this.config.disableAwayServiceGlobally ? true : false;

        const addressApiBase = this.config.addressApi;
        this.config.addressApiStreetAddress = addressApiBase + '/address-api/address/v1/address/streetAddress';
        this.config.addressApiPayerconfig = addressApiBase + '/selfEnrollment/v1/ssep/config';

        const enrollmentApiBase = this.config.enrollmentApi;
        this.config.enrollmentApiExternalLookup = `${enrollmentApiBase}/v1/users/externalLookup`;
        this.config.enrollmentApiValidate = `${enrollmentApiBase}/v1/users/validate`;
        this.config.enrollmentApiPayment = `${enrollmentApiBase}/v1/users/payment`;
        this.config.enrollmentApiMessageConvert = `${enrollmentApiBase}/v1/message/convert`;
        this.config.enrollmentApiMessageEnroll = `${enrollmentApiBase}/v1/message/static`;
        this.config.enrollmentApiOktaAuth = `${enrollmentApiBase}/v1/authn/login`;
        this.config.enrollmentApiOktaLogout = `${enrollmentApiBase}/v1/authn/logout`;
        this.config.enrollmentApiSsepAuth = `${enrollmentApiBase}/v1/ssepauth/authenticate`;

        const platformApiBase = this.config.platformApi;
        this.config.platformApiAccounts = `${platformApiBase}/api/centralstation/accounts`;
        this.config.platformApiEligibility = `${platformApiBase}/api/centralstation/accounts/contacts/eligibility`;

        this.config.localStorageFirstClickAt = 'firstClickAt';
        this.config.localStoragePlatformToken = 'platformToken';
        this.config.localStorageRepUser = 'repUser';
        this.config.localStorageVerifyCount = 'verifyCount';

        this.config.enrollmentApiSearchBase = `${enrollmentApiBase}/search`;
        this.config.enrollmentApiSearchName = `${this.config.enrollmentApiSearchBase}/byName/`;
        this.config.enrollmentApiSearchPhone = `${this.config.enrollmentApiSearchBase}/byHomePhone/`;
        this.config.enrollmentApiSearchMbi = `${this.config.enrollmentApiSearchBase}/byMrnMemNbr/`;
    }
}

export const initConfig = (config: AppConfig) => () => config.load();
