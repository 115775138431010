import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { ConversionFormService } from '../../services/conversion-form.service';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormsService } from 'src/app/enrollment-forms/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConversionStepperControlComponent } from '../conversion-stepper-control/conversion-stepper-control.component';

@Component({
  selector: 'app-conversion-finalize',
  templateUrl: './conversion-finalize.component.html',
  styleUrls: ['./conversion-finalize.component.css']
})
export class ConversionFinalizeComponent implements OnInit {
  carePhoneNo: string;
  isLoggedIn = false;
  emailSending = false;
  emailRequestSent = false;
  emailSentSuccessfully = false;
  emailForm: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private conversionFormsService: ConversionFormService,
    private formsService: FormsService,
    private emailFormBuilder: FormBuilder,
    private authService: AuthService,
    private conversionStepperControlComponent: ConversionStepperControlComponent
  ) { }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.conversionFormsService.providerDetails$.pipe(takeUntil(this.destroy$)).subscribe((memberCareType: Provider) => {
      if (memberCareType) {
        this.carePhoneNo = memberCareType.phoneno || memberCareType.helpPhoneNumber;
      }
    });

    this.emailForm = this.emailFormBuilder.group({
      email: ['',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[A-Za-z0-9_.%+-]{1,}@[A-Za-z0-9-]{1,}[.]{1}[A-Za-z.]{2,}$/)
        ]
      ],
    });
  }

  startOver() {
    this.conversionFormsService.formConversion.reset();
    this.conversionFormsService.formPayment.reset();
    this.conversionStepperControlComponent.startOver();
  }

  sendEmail() {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
    } else {
      this.emailSending = true;
      const emailAddress = this.emailForm.get('email').value;
      const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(this.conversionFormsService.lastSubmittedConversion.price);
      const emailText = `${this.conversionFormsService.lastSubmittedConversion.firstName} ${
        this.conversionFormsService.lastSubmittedConversion.lastName}`;
      const amount = `${formattedPrice}`;
      this.formsService.sendConversionEmail(emailAddress, emailText,amount).subscribe(
        () => {
          this.emailSending = false;
          this.emailRequestSent = true;
          this.emailSentSuccessfully = true;
        }, () => {
          this.emailSending = false;
          this.emailRequestSent = true;
          this.emailSentSuccessfully = false;
        });
    }
  }
}
